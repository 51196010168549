<template>
  <div>
    <Menu />
    <div id="homes">
      <div class="indexPage">
        <!-- banner -->
        <div class="banner">
          <div class="content">
            <div class="indexClassify" @mouseleave="indexClassifyIndex=-1">
              <div class="icList">
                <div class="indexClassifyLi" v-for="(item,index) in listType" :key="index"  @mouseenter="indexClassifyIndex=index">
                  <p class="icLi_title txtOver">{{item.Industry_Title}}</p>
                  <div class="icLi_hots d-flex d-flex-middle d-flex-wrap">
                    <router-link :to=" {path:'/CourseSelection',query:{pId:item.Industry_Id,id:itemClil.Industry_Id}}" v-for="(itemClil,indexClil) in item.chil" :key="indexClil">{{itemClil.Industry_Title}}</router-link>
                  </div>
                </div>
              </div>
              <div class="indexClassifyLi_list" v-for="(item,index) in listType" :key="index" :class="{'indexClassifyLi_listShow':indexClassifyIndex == index}">
                  <p class="icLi_list_title">{{item.Industry_Title}}</p>
                  <div class="icLi_list_alinks d-flex-wrap d-flex d-flex-middle">
                    <router-link :to=" {path:'/CourseSelection',query:{pId:item.Industry_Id,id:itemClil.Industry_Id}}" v-for="(itemClil,indexClil) in item.chil" :key="indexClil">{{itemClil.Industry_Title}}</router-link>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <!-- 推荐 -->
        <div class="recommend">
          <div class="content d-flex d-flex-middle d-flex-between">
            <router-link :to="'/TeacherHall'" class="recommendLi d-flex d-flex-middle d-flex-center">
              <img src="../assets/images/index_tabIcon_1.png" />
              <div>
                <p>名师云集</p>
                <p>精英名师团队在线教学</p>
              </div>
            </router-link>
            <div class="recommendLi d-flex d-flex-middle d-flex-center">
              <img src="../assets/images/index_tabIcon_2.png" />
              <div>
                <p>视频教学</p>
                <p>随时随地想学就学</p>
              </div>
            </div>
            <router-link :to="'/CourseSelection'" class="recommendLi d-flex d-flex-middle d-flex-center">
              <img src="../assets/images/index_tabIcon_3.png" />
              <div>
                <p>精品好课</p>
                <p>精品好课应有尽有</p>
              </div>
            </router-link>
             <router-link :to="'/Betreff'" class="recommendLi d-flex d-flex-middle d-flex-center">
              <img src="../assets/images/index_tabIcon_4.png" />
              <div>
                <p>海量题库</p>
                <p>精选题库让你学无止境</p>
              </div>
            </router-link>
          </div>
        </div>
        <!-- 精品课程 -->
        <div class="ExcellentCourses bg_fff">
          <div class="content">
            <div class="iconTitle d-flex d-flex-middle d-flex-between">
              <div class="d-flex d-flex-middle">
                <img src="../assets/images/index_titleIcon_1.png" />
                <p>精品课程</p>
              </div>
              <router-link to="/CourseSelection">MORE+</router-link>
            </div>
            <!--  -->
            <div class="ecList d-flex d-flex-middle">
              <router-link :to="'/Curriculum/' + item.course_Id" class="ecLi" v-for="(item,index) in listJpCourse" :key="index">
                <div class="ec_content">
                  <div class="ec_img" :style="{'background-image': 'url('+$store.state.httpUrl + item.course_ImaPath+')'}"></div>
                  <div class="ec_info">
                    <p class="ec_title">{{item.course_Title}}</p>
                    <div class="ec_bottom d-flex d-flex-middle d-flex-between">
                      <div class="ec_price">
                        <p>￥{{item.course_Price}}</p>
                        <p>特惠价</p>
                      </div>
                      <div class="ec_icon">
                        <img src="../assets/images/audition.png" />
                        <p>免费试听</p>
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <!-- 热门课程 -->
        <div class="HotCourses bg_fff">
          <div class="content">
            <div class="iconTitle d-flex d-flex-middle d-flex-between">
              <div class="d-flex d-flex-middle">
                <img src="../assets/images/index_titleIcon_2.png" />
                <p>热门课程</p>
              </div>
              <router-link to="/CourseSelection">MORE+</router-link>
            </div>
            <div class="hcList d-flex d-flex-middle d-flex-wrap">
              <router-link :to="'/Curriculum/' + item.course_Id" class="hcLi" v-for="(item,index) in listHotCourse" :key="index">
                <div class="hc_content">
                  <div class="hc_img diyImg" :style="{'background-image': 'url('+$store.state.httpUrl + item.course_ImaPath+')'}">
                    <img src="../assets/images/hot.png" />
                  </div>
                  <div class="hc_info">
                    <p class="hc_title">{{item.course_Title}}</p>
                    <p class="hc_label">{{item.industry_Title}}</p>
                    <div class="hc_bottom d-flex d-flex-middle d-flex-between">
                      <p>已有<span>{{item.course_ReportCount}}</span>人报名</p>
                      <a href="">立即报名</a>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <!-- 广告图 -->
        <img src="../assets/images/index_img_1.png" class="w100" />
        <!-- 名师堂 -->
        <div class="teacherHall diyImg">
          <div class="content bg_fff">
            <div class="d-flex d-flex-middle d-flex-between">
              <div class="iconTitle d-flex d-flex-middle d-flex-between">
                <div class="d-flex d-flex-middle">
                  <img src="../assets/images/index_titleIcon_3.png" />
                  <p>名师堂</p>
                </div>
              </div>
              <div class="th_tabs d-flex d-flex-middle d-flex-end">
                <!-- <a href="" class="color3 fontSize14">全部</a> -->
                <div class="th_tabs_list d-flex d-flex-middle">
                  <p :class="{active:teacherlistActive==-1}" @click="changeTeacherType(-1,-1)">全部</p>
                  <p v-for="(item,index) in listType" :key="index" :class="{active:teacherlistActive==index}" @click="changeTeacherType(item.Industry_Id,index)">{{item.Industry_Title}}</p>
                </div>
              </div>
            </div>
            <div class="th_list d-flex d-flex-middle d-flex-wrap">
              <router-link :to="'/TeacherDetail/' + item.teacher_Id" class="th_li d-flex d-flex-middle" v-for="(item,index) in listTeacher" :key="index">
                <img :src="$store.state.httpUrl + item.teacher_HeadImgPath" />
                <div class="th_info">
                  <p class="th_name">{{item.teacher_RealName}}</p>
                  <p class="th_txt color3 fontSize14 txtOver">主讲领域：{{item.industry_Title}}</p>
                  <p class="th_txt color3 fontSize14 txtOver">教龄：{{item.teacher_Experience}}</p>
                  <router-link :to="'/TeacherDetail/' + item.teacher_Id">试听TA的课</router-link>
                </div>
              </router-link>
            </div>
            <div class="none" v-if="listTeacher.length==0">
              <img src="../assets/images/none.png"/>
            </div>
            <div class="d-flex d-flex-center">
              <router-link to="/TeacherHall" class="moreDiv d-flex d-flex-middle d-flex-center">
                <p>了解更多</p>
                <img src="../assets/images/right_icon_2.png" />
              </router-link>
            </div>
          </div>
        </div>
        <!-- 学校 -->
        <div class="school">
          <img src="../assets/images/index_schoolBg.png" class="schoolBg w100" />
          <div class="schoolView">
            <p class="sv_title">要考证就上湖北安保</p>
            <div class="sv_subTitle d-flex d-flex-center d-flex-middle">
              <p>名师团队专业授课</p>
              <p>精品课程在线学习</p>
            </div>
            <router-link to="/AboutUs" class="moreDiv moreDivF d-flex d-flex-middle d-flex-center">
              <p>了解更多</p>
              <img src="../assets/images/right_icon_3.png" />
            </router-link>
            <img src="../assets/images/school.png" class="svImg" />
            <p class="sv_txt">湖北安保隶属于湖北安保职业培训学校，自成立以来，始终以“成为中国一流的服务品牌，建立中国一流的培训学校”为愿景，孜孜以求，不断开拓创新，帮助学员成功提升技能并获得职业资格证书，获得了良好的品牌知名度。在消防设施操作员、注册消防工程师，造价工程师、监理工程师、安全工程师、建筑师等多个考前辅导方面积累了丰富的经验。历年来，考试通过率普遍高于同行业平均水平40%，良好的成绩和口碑奠定了行业内的 领先地位，代表了消防建筑考辅最高水平。学校在“营销、服务、产品”三大模块提升核心竞争力，通过“系统化、标准化、流程化、规范化”提升学校的服务水平。</p>
          </div>
        </div>
        <!-- 评论、资讯 -->

        <!-- 评论、资讯 -->
        <div class="comments_news diyImg">
          <div class="content d-flex d-flex-top d-flex-center">
            <div class="comments cn">
              <div class="cnTitle">
                <img src="../assets/images/comments.png" />
                <p>学员评论</p>
              </div>
              <div class="cn_l_list">
                <div class="swiper-container">
                  <swiper class="roll-ul" :options="swiperOption" ref="mySwiper" v-if="commentsList.length>0">
                    <swiper-slide v-for="(item,index) in commentsList" :key="index" style="height:120px">
                      <div class="cn_l_li d-flex d-flex-top">
                        <img src="../assets/images/userImg.png" />
                        <div class="cn_l_info">
                          <div class="cn_l_top d-flex d-flex-middle d-flex-between">
                            <p class="color3 fontSize16">{{item.user_NickName}}</p>、
                            <p class="color9 fontSize12">{{(item.review_CreateTime.substring(0,10)).replace(/-/g, '-')}}</p>
                          </div>
                          <p class="cn_l_txt color3 fontSize14 txtOver2">{{item.review_Content}}</p>
                        </div>
                      </div>
                    </swiper-slide>
                  </swiper>
                </div>
              </div>
            </div>
            <div class="news cn">
              <div class="cnTitle">
                <img src="../assets/images/Information.png" />
                <p>新闻资讯</p>
              </div>
              <div class="cn_r_list">
                <div class="cn_r_top d-flex d-flex-middle d-flex-between">
                  <div class="cn_r_tabs d-flex d-flex-middle">
                    <p :class="{active:newsTypeActive==0}" @click="changeNews(0)">行业新闻</p>
                    <p :class="{active:newsTypeActive==1}" @click="changeNews(1)">学校新闻</p>
                  </div>
                  <router-link to="/News" class="color9 fontSize12">查看更多 > </router-link>
                </div>
                <div class="cn_r_listDiv">
                  <router-link :to="'/NewsDetail/' + item.news_Id" class="cn_r_li d-flex d-flex-middle d-flex-between" v-for="(item,index) in newList" :key="index">
                    <p class="txtOver">{{item.news_Title}}</p>
                    <p class="color9 fontSize14">{{(item.news_ReleaseDate.substring(0,10)).replace(/-/g, '-')}}</p>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <Foot />
    <RightGuide />

  </div>
</template>

<script>
import Menu from "@/components/Menu";
import Foot from "@/components/Foot";
import RightGuide from "@/components/RightGuide";
import { swiper, swiperSlide } from 'vue-awesome-swiper'

import { pcIndex, splitListTeacher, splitListNews, tjReview } from "@/api/app"

export default {
  name: "Home",
  components: {
    Menu, Foot, RightGuide, swiper, swiperSlide
  },
  data() {
    return {
      indexClassifyIndex: -1,
      listJpCourse: [], //精品课程
      listHotCourse: [], //热门课程
      listTeacherAll: [],//默认显示所有的名师堂单独存起来，
      listTeacher: [], //名师堂
      listType: [], //分类
      teacherlistActive: -1, //名师堂类型Active
      newList: [], //新闻
      newsTypeActive: 0,//新闻Active
      commentsList: [], //评论列表
      swiperOption: {
        notNextTick: true,
        slidesPerView: 4,
        direction: "vertical",   //控制滚动的方向
        paginationClickable: true,
        // autoplay: {
        //   delay: 20000,  //这里需要注意，如果想每2秒去自动切换，直接autoplay:2000是失效的，
        //   disableOnInteraction: false,
        // },
        autoplay: 2000,
        loop: false,
        on: {
          click: function (e) {
            let realIndex = this.realIndex;
            //  console.log(e,'轮播')
            vm.jumpDesc(realIndex)  //这里调用的是定义在methods里面的方法
          }
        }
      },
    };
  },

  mounted() {
    this.getPcIndex()
    this.getSplitListNews()
    this.getTjReview()
  },

  methods: {

    //首页接口
    getPcIndex() {
      pcIndex().then((res) => {
        if (res.status) {
          const data = res.data
          this.listJpCourse = data.listJpCourse
          this.listHotCourse = data.listHotCourse
          this.listTeacher = data.listTeacher
          this.listTeacherAll = data.listTeacher
          this.listType = data.listType

          
        }
      })
    },

    //名师堂
    splitListTeacher(id) {
      console.log(id)
      const params = {
        industry_Id: id,
        pageSize:6,
        page: 1
      }
      splitListTeacher(params).then((res) => {
        if (res.status) {
          const data = res.data
          console.log(data.listData)
          this.listTeacher = data.listData
        }
      })
    },

    //点击名师堂分类
    changeTeacherType(id, index) {
      this.teacherlistActive = index
      //如果-1 显示全部， 赋值首页接口里的默认数据
      if (id == -1) {
        this.listTeacher = this.listTeacherAll
        return
      }

      this.splitListTeacher(id)
    },

    //点击新闻分类
    changeNews(index) {
      this.newsTypeActive = index
      this.getSplitListNews()
    },

    //获取新闻
    getSplitListNews() {
      const params = {
        news_Type: this.newsTypeActive,
        page: 1
      }
      splitListNews(params).then((res) => {
        if (res.status) {
          const data = res.data
          this.newList = data.listData
        }
      })
    },

    //获取评论
    getTjReview() {
      tjReview().then((res) => {
        if (res.status) {
          const data = res.data
         this.commentsList = data
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>

#homes {
.banner {
  height: 530px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../assets/images/index_banner.png);
}
.indexClassify {
  position: relative;
  width: 210px;
  height: 530px;
  background-color: #fff;
}

.icList{
  position: relative;
  width: 210px;
  height: 530px;
  background-color: #fff;
  overflow-y: auto;
  overflow-x: hidden;
}
.icList::-webkit-scrollbar {         /*滚动条整体样式*/
  width:5px;
  height:3px;
}
.icList::-webkit-scrollbar-thumb {   /*滚动条里面小方块样式*/
  border-radius:100px;
  -webkit-box-shadow:inset 0 0 5px rgba(0,0,0,0.2);
  background:rgba(0,0,0,0.1);;
} 
.icList::-webkit-scrollbar-track {   /*滚动条里面轨道样式*/
  -webkit-box-shadow:inset 0 0 5px rgba(0,0,0,0.2);
  border-radius:0;
  background:rgba(0,0,0,0.1);
}
.indexClassifyLi {
  height: 20%;
  box-sizing: border-box;
  padding: 0 15px;
}
.icLi_title {
  min-height: 48px;
  line-height: 48px;
  background: url(../assets/images/right_icon_1.png) right center no-repeat;
  background-size: 6px;
  font-size: 16px;
  color: #333;
  width: 180px;
  box-sizing: border-box;
}
.indexClassifyLi .icLi_hots {
  width: 180px;
  height: 45px;
  overflow: hidden;
}
.indexClassifyLi .icLi_hots a {
  display: block;
  line-height: 24px;
  color: #999;
  font-size: 12px;
  margin-right: 10px;
}
.indexClassifyLi_list {
  position: absolute;
  top: 0;
  left: 210px;
  box-sizing: border-box;
  padding: 0 50px;
  background-color: rgba(255, 255, 255, 0.9);
  width: 480px;
  height: 530px;
  transition: all 1s;
  display: none;
}
.icLi_list_title {
  height: 63px;
  line-height: 64px;
  border-bottom: 1px solid #ccc;
  color: #333;
  font-size: 18px;
  font-weight: bold;
}
.icLi_list_alinks {
  box-sizing: border-box;
  padding: 10px 0;
}
.icLi_list_alinks a {
  display: block;
  // width: 25%;
  line-height: 40px;
  text-align: center;
  color: #333;
  font-size: 14px;
  margin-right: 20px;
}
.icLi_list_alinks a:hover {
  color: #0087ed;
}
/*  */
.indexClassifyLi:hover {
  background-color: #0087ed;
}
.indexClassifyLi:hover .icLi_title {
  background: url(../assets/images/right_icon_1_.png) right center no-repeat;
  background-size: 6px;
  color: #fff;
}
.indexClassifyLi:hover .icLi_hots a {
  color: #fff;
}
.indexClassifyLi_listShow.indexClassifyLi_list{
  display: block;
}
/*  */
.recommend {
  height: 180px;
  background-color: #fafafa;
}
.recommendLi img {
  width: 120px;
  height: 120px;
  margin-right: 10px;
}
.recommendLi div p:nth-of-type(1) {
  color: #333;
  font-size: 24px;
  margin-bottom: 5px;
}
.recommendLi div p:nth-of-type(2) {
  font-size: 14px;
  color: #333;
}
/*  */
.iconTitle {
  height: 40px;
  margin-bottom: 20px;
}
.iconTitle img {
  width: 40px;
  height: 40px;
}
.iconTitle p {
  font-size: 24px;
  color: #333;
  font-weight: bold;
  margin-left: 10px;
}
.iconTitle a {
  font-size: 14px;
  color: #999;
}
/*  */
.ExcellentCourses {
  box-sizing: border-box;
  padding: 30px 0;
}
.ecList {
}
.ecLi {
  width: 25%;
}
.ec_content {
  width: 294px;
  margin: 0 auto;
  height: 300px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;
}
.ec_img {
  height: 164px;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.ec_img img {
  position: absolute;
  right: 0;
  top: 0;
  width: 86px;
  height: 86px;
}
.ec_info {
  box-sizing: border-box;
  padding: 10px;
}
.ec_title {
  width: 100%;
  height: 30px;
  line-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  font-size: 18px;
  color: #333;
  font-weight: bold;
}
.ec_bottom {
  margin-top: 20px;
}
.ec_price p {
  color: #e02727;
  font-size: 14px;
}
.ec_price p:nth-of-type(1) {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
  text-indent: -5px;
}
.ec_icon {
  text-align: center;
  color: #0091ff;
  font-size: 14px;
}
.ec_icon img {
  width: 30px;
  height: 30px;
}
/*  */
.HotCourses {
  box-sizing: border-box;
  padding: 30px 0;
}
.hcLi {
  width: 25%;
  margin-bottom: 20px;
}
.hc_content {
  width: 294px;
  margin: 0 auto;
  height: 300px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
}
.hc_img {
  height: 164px;
  position: relative;
}
.hc_img img {
  position: absolute;
  right: 0;
  top: 0;
  width: 86px;
  height: 86px;
}
.hc_info {
  box-sizing: border-box;
  padding: 10px;
}
.hc_title {
  width: 100%;
  height: 30px;
  line-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  font-size: 18px;
  color: #333;
  font-weight: bold;
}
.hc_label {
  display: inline-block;
  padding: 0 10px;
  height: 24px;
  line-height: 24px;
  border-radius: 12px;
  background: #eaf1ff;
  border: 1px solid #0090f0;
  color: #0090f0;
  margin: 10px 0 15px 0;
}
.hc_bottom p {
  color: #999;
  font-size: 14px;
}
.hc_bottom p span {
  color: #e02727;
  font-size: 14px;
}
.hc_bottom a {
  width: 68px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  background: #0091ff;
  border-radius: 5px;
  color: #fff;
}
/* 名师堂 */
.teacherHall {
  min-height: 600px;
  background-image: url(../assets/images/index_teacherBg.png);
  margin-top: -5px;
  box-sizing: border-box;
  padding: 60px 0;
}
.teacherHall .content {
  box-sizing: border-box;
  padding: 10px 10px 60px 10px;
  border-radius: 10px;
}
.th_tabs a {
  width: 100px;
  height: 30px;
  line-height: 30px;
  text-align: center;
}

.th_tabs_list p {
  min-width: 80px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #333;
  border-radius: 5px;
  font-size: 14px;
  margin: 0 10px;
  padding: 0 5px;
  cursor: pointer;
}
.th_tabs .active {
  background: #0087ed;
  color: #fff;
}
.th_list {
  margin-top: 30px;
}
.th_li {
  width: 390px;
  height: 210px;
  background: linear-gradient(90deg, #f2f4f3 0%, #ffffff 100%);
  box-sizing: border-box;
  padding: 25px 20px;
  margin-right: 25px;
  margin-bottom: 40px;
  cursor: pointer;
}
.th_li:hover {
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
}
.th_li:nth-of-type(3n) {
  margin-right: 0;
}
.th_li img {
  width: 160px;
  height: 160px;
}
.th_info {
  width: calc(100% - 180px);
  margin-left: 20px;
  height: 100%;
}
.th_name {
  color: #333;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}
.th_txt {
  line-height: 30px;
  color: #333;
  font-size: 14px;
  max-width: 100%;
}
.th_info a {
  display: inline-block;
  width: 130px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: #ffffff;
  border: 1px solid #0087ed;
  border-radius: 18px;
  margin-top: 10px;
  color: #0087ed;
  box-sizing: border-box;
  padding-right: 25px;
  background: url(../assets/images/listen.png) right 15px center no-repeat;
  background-size: 20px;
}
/*  */
.moreDiv {
  width: 150px;
  height: 46px;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 24px;
  cursor: pointer;
}
.moreDiv a {
  color: #999;
  font-size: 16px;
}
.moreDiv img {
  width: 18px;
  height: 9px;
  margin-left: 10px;
}
.moreDivF {
  background-color: transparent;
  border-color: #fff;
}
.moreDivF a {
  color: #fff;
}
/*  */
.school {
  position: relative;
  background-color: #fff;
  overflow: hidden;
}
.schoolBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.schoolView {
  position: relative;
  z-index: 22;
  width: 1020px;
  margin: 60px auto;
}
.sv_title {
  color: #fff;
  font-size: 48px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}
.sv_subTitle p {
  color: #fff;
  font-size: 24px;
  margin: 0 10px;
}
.school .moreDivF {
  margin: 30px auto;
  color: #fff;
}
.svImg {
  display: block;
  width: 754px;
  margin: 0 auto 30px auto;
}
.sv_txt {
  color: #333;
  font-size: 16px;
  line-height: 30px;
  text-indent: 2em;
  padding-bottom: 30px;
}
/*  */
.comments_news {
  box-sizing: border-box;
  padding: 60px 0;
  background-image: url(../assets/images/index_bottomBg.png);
}
.cnTitle {
  margin-bottom: 30px;
}
.cnTitle img {
  height: 40px;
  margin-bottom: -20px;
}
.cnTitle p {
  color: #333;
  font-size: 36px;
  font-weight: bold;
}
.cn_l_list,
.cn_r_list {
  background-color: #fff;
  width: 630px;
  height: 500px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  padding: 0 30px;
}
.cn_l_list .swiper-container {
  width: 100%;
  height: 100%;
}
.cn_l_li {
  box-sizing: border-box;
  padding: 20px 0;
  border-bottom: 1px solid #eee;
}
.cn_l_li img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 20px;
}
.cn_l_info {
  flex: 1;
}
.cn_l_txt {
  line-height: 20px;
  height: 40px;
  margin-top: 10px;
}
.cn {
  margin: 0 20px;
}
.cn_r_list {
  padding: 0;
}
.cn_r_top {
  border-bottom: 1px solid #eee;
  height: 54px;
  box-sizing: border-box;
  padding: 0 30px 0 20px;
}
.cn_r_tabs p {
  line-height: 54px;
  color: #333;
  font-size: 16px;
  text-align: center;
  width: 120px;
  cursor: pointer;
}
.cn_r_tabs .active {
  font-weight: bold;
  font-size: 20px;
  position: relative;
}
.cn_r_tabs .active::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -2px;
  transform: translateX(-50%);
  width: 60px;
  height: 4px;
  background: #0087ed;
}
.cn_r_listDiv {
  box-sizing: border-box;
  padding: 0 30px;
  height: 446px;
  overflow-y: auto;
}
.cn_r_li {
  height: 60px;
  border-bottom: 1px solid #eee;
  position: relative;
  box-sizing: border-box;
  padding-left: 20px;
  cursor: pointer;
}
.cn_r_li::before {
  position: absolute;
  left: 0;
  top: 26px;
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #cccccc;
}
.cn_r_li p:nth-of-type(1) {
  width: calc(100% - 90px);
  height: 20px;
  line-height: 20px;
}
.cn_r_li:hover p:nth-of-type(1) {
  color: #0087ed;
}
.comments_news .moreDiv {
  margin: 50px auto 0 auto;
}
}

</style>